import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    outline: none;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }
  
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.35;
    color: #4f4f4f;
    font-family: "Red Hat Display";
  }
  
  body, input, textarea {
    font-family: 'Red Hat Display', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Red Hat Display', sans-serif;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
  }
  
  p {
    margin-bottom: 10px;
    margin-top: 0;
  }
  
  .embed-responsive {
    position: relative;
    &::before {
      height: 0;
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  
  .no-decoration {
    text-decoration: none;
  }
  
  .nav-box {
    display: flex;
    flex-wrap: wrap;

    .nav-item {
      display: block;
      padding: 10px 20px 20px;
      font-size: 18px;
      line-height: 23.81px;
      position: relative;

      &.selected {
        font-weight: bold;

        &:before {
          content: '';
          background: #C4C4C4;
          position: absolute;
          height: 1px;
          left: 0;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
  
  .input {
    padding: 0 20px;
    font-size: 18px;
    font-weight: normal;
    color: #828282;
    background: none;
    border: 1px solid #BDBDBD;
    width: 100%;
    border-radius: 8px;
    height: 60px;
    display: flex;
    align-items: center;
  }
`
