import styled from 'styled-components'
import { BoxHelper } from './utils/box-helper'

type BoxType = string | number | string[] | number[]

export type BoxProps = {
  w?: BoxType
  maxW?: BoxType
  minW?: BoxType
  h?: BoxType
  maxH?: BoxType
  minH?: BoxType
  px?: BoxType
  py?: BoxType
  pl?: BoxType
  pr?: BoxType
  pt?: BoxType
  pb?: BoxType
  p?: BoxType
  mx?: BoxType
  my?: BoxType
  ml?: BoxType
  mr?: BoxType
  mt?: BoxType
  mb?: BoxType
  m?: BoxType
  bg?: BoxType
  bgc?: BoxType
  d?: BoxType
  fw?: BoxType
  pos?: 'relative' | 'absolute' | 'static'
  opacity?: BoxType
}

export const Box = styled.div<BoxProps>`
  ${(props) => BoxHelper.apply(props)}
`
