import React from 'react'

type Props = {
  open: boolean
  content: any
  alertBox: (data: any) => void
  closeAlertBox: () => void
}

const AlertBoxContext = React.createContext<Props|null>(null)

export const AlertBoxProvider: React.FC = ({ children }: any) => {
  const [open, setOpen] = React.useState(false)
  const [content, setContent] = React.useState(null)

  const alertBox = (data: any) => {
    setOpen(true)
    setContent(data)
  }

  const closeAlertBox = () => {
    setOpen(false)
    setContent(null)
  }

  return (
    <AlertBoxContext.Provider value={{ open, content, alertBox, closeAlertBox }}>
      {children}
    </AlertBoxContext.Provider>
  )
}

export const useAlertBox = () => {
  const context = React.useContext(AlertBoxContext)

  if (!context) throw new Error('useAlertBox must be used within a provider AlertBoxProvider')

  return { ...context }
}
