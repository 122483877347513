import React from 'react'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'
// import { t } from '../../adapters/translate/translate'

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  height: 100%;
  p{
    margin-bottom: 0;
  }
`

const Footer = () => {
  return (
    <Box className="footer">
      <p>Copyright © 2021 <span>IzapSoftworks</span></p>
      {/* <Link className="link-default link-default-black" to="/suporte" >{t('LINK_LABEL_SUPPORT')}</Link> */}
    </Box>
  )
}

export default Footer
