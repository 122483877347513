import styled from 'styled-components'

export const BaseStyled = styled.div`
  background-color: #f4f4f4;
  a{
    text-decoration: none;
  }
  .footer-main {
    background-color: #fff;

    .footer {
      p {
        span {
          font-weight: bold;
        }
      }
      
      .link-default-black{
        font-size: 20px;
        line-height: 24.4px;
        text-decoration: none;
        color: #4f4f4f;      
      }
    }  
  }
`
