import styled, { css } from 'styled-components'

type Props = {
  color?: 'blue' | 'green' | 'dark-blue' | 'grey'
  background?: string
  foregroundColor?:string
}

export const Button = styled.button<Props>`
  font-size: 24px;
  line-height: 31,75px;
  font-weight: 400;
  padding: 10px 50px;
  border-radius: 8px;
  cursor: pointer;
  /* margin: auto; */
  display: block;
  transition: all .3s ease;
  border: 0;
  color: #ffffff;

  ${({ color }) => color === 'dark-blue' && css`
    background: #212366;
  
    &:hover {
      background-color: #292b83;
    }
  `}
  
  ${({ color }) => color === 'blue' && css`
    background: #009BDF;
  
    &:hover {
      background-color: #00aaf0;
    }
  `}
  
  ${({ color }) => color === 'green' && css`
    background: #219653;
  
    &:hover {
      background-color: #2abf6a;
    }
  `}
  
  ${({ background }) => background && css`
    background: ${background};
  
    &:hover {
      opacity: .6;
    }
  `}
  ${({ foregroundColor }) => foregroundColor && css`
    color: ${foregroundColor};  
  `}
  
  ${({ color }) => color === 'grey' && css`
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D9D9D9;
  
    &:hover {
      background-color: #DCDCDC;
    }
  `}
`
