import React, { lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GuardFunction, GuardProvider } from 'react-router-guards'
import { RoutesGenerate } from './'
import PageNotFound from '../../pages/errors/page-not-found/page-not-found'
import { Loading } from '../ui'

// layouts
import LayoutBase from '../../../main/factory/layouts/base-factory'

// Candidates
const Opportunity = lazy(() => import('../../../main/factory/pages/opportunity/opportunity'))
const StartTest = lazy(() => import('../../../main/factory/pages/assessment/start-test'))
const Test = lazy(() => import('../../../main/factory/pages/assessment/test'))

type RoutesProps = {
  guardsRoutes: GuardFunction
}

const Routes: React.FC<RoutesProps> = ({ guardsRoutes }: RoutesProps) => {
  const data = [

    {
      layout: LayoutBase,
      data: [
        { path: '/opportunities/:opportunityUuid', component: Opportunity, exact: true },
        { path: '/opportunities/:opportunityUuid/:assessmentUuid', component: StartTest, exact: true },
        { path: '/opportunities/:opportunityUuid/:assessmentUuid/test', component: Test, exact: true },
        { path: '*', component: PageNotFound, exact: true }
      ]
    }
  ]

  return (
    <BrowserRouter>
      <GuardProvider guards={[guardsRoutes]} loading={Loading} error={PageNotFound}>
        <RoutesGenerate data={data} />
      </GuardProvider>
    </BrowserRouter>
  )
}

export default Routes
