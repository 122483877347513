import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import Loading from '../ui/loading/loading'

type MetaProps = {
  auth?: boolean
}

type RoutesGenerateProps = {
  data: {
    layout: any,
    meta?: MetaProps
    data: {
      path: string,
      component: any,
      exact?: boolean;
      meta?: MetaProps
    }[]
  }[]
}

const RoutesGenerate: React.FC<RoutesGenerateProps> = ({ data }: RoutesGenerateProps) => {
  return (
    <Switch>
      {data.map(({ layout: Layout, data: items, ...rest }, index) => (
        <Route key={index} path={items.map(item => item.path)}>
          <Layout>
            <Suspense fallback={<Loading />}>
              <Switch>
                {items.map(({ path, component, ...route }, idx) => (
                  <GuardedRoute
                    key={`${index}-${idx}`}
                    path={path}
                    component={component}
                    meta={{
                      ...rest.meta,
                      ...route.meta
                    }}
                    {...route}
                    exact={route.exact || true}
                  />
                ))}
              </Switch>
            </Suspense>
          </Layout>
        </Route>
      ))}
    </Switch>
  )
}

export default RoutesGenerate
