import React from 'react'
import {
  ModalDialogBody,
  ModalDialogClose,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogHeaderTitle,
  ModalDialogOverlay
} from './modal-styled'
import { useModal } from '../../../contexts'

const Modal = () => {
  const { open, content, title, modalClose, customClose, addCustomClose } = useModal()

  const handleClose = () => {
    modalClose()
    if (customClose) {
      customClose()
      addCustomClose(null)
    }
  }

  if (!open) return null

  return (
    <>
      <ModalDialogOverlay onClick={handleClose} />
      <ModalDialogContent>
        <ModalDialogHeader>
          {title && <ModalDialogHeaderTitle>{title}</ModalDialogHeaderTitle>}
          <ModalDialogClose onClick={handleClose}>&times;</ModalDialogClose>
        </ModalDialogHeader>
        <ModalDialogBody>
          {content}
        </ModalDialogBody>
        <ModalDialogFooter />
      </ModalDialogContent>
    </>
  )
}

export default Modal
