import React from 'react'
import Footer from '../footer/footer'
import { Box, Container } from '../ui'
import { BaseStyled } from '../../assets/styled-components/base-layout'

type LayoutProps = {
  children?: React.ReactNode
}

const LayoutBase: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <BaseStyled>
      <Container>
        <Box pb={30}>{children}</Box>
      </Container>

      <div className="footer-main">
        <Container>
          <Footer />
        </Container>
      </div>
    </BaseStyled>
  )
}

export default LayoutBase
