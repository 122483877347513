import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../../components/ui'
import { CardStyled } from '../../../assets/styled-components/test'

const PageStyled = styled.div`
    min-height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
`
const FormStyled = styled.div`

  h2{
    color:#242668;
    margin-bottom: 30px;
    font-weight: 400;
  }
  i{
    color: #242668;
    font-size: 1.3em;
    background-color: #f4f4f4;
    border-radius: 50%;
    max-width: 40px;
    width: 100%;
    height: 40px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }
  
`
const PageNotFound = () => {
  return (
   <PageStyled>
      <Flex flexDir="row" alignItems="center" justifyContent="center">
        <Box w="100%" maxW="450px">
          <FormStyled>
             <CardStyled>
              <Flex flexDir="row" alignItems="flex-start" justifyContent="center">
                <i className="ri-close-line" />
                <h2>Página não encontrada</h2>
              </Flex>
            </CardStyled>
          </FormStyled>
        </Box>
      </Flex>
    </PageStyled>
  )
}

export default PageNotFound
