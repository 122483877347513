export default {
  CognitoDefaultException: 'Inconsistência nos dados enviados.',
  UsernameExistsException: 'Já existe uma conta com o email informado',
  CodeMismatchException: 'Código de verificação inválido, tente novamente',
  ExpiredCodeException: 'Código inválido/expirado',
  LimitExceededException: 'Limite de tentativas excedidas, tente novamente mais tarde',
  NewPasswordRequired: 'Este é seu primeiro acesso, crie uma nova senha para acessar a plataforma',
  UserNotConfirmedException: 'Usuário não confirmado',
  UserNotFoundException: 'E-mail e/ou senha incorretos',
  NotAuthorizedException: 'E-mail e/ou senha incorretos.',
  NotAuthorizedExceptionTempPasswordExpired: 'A senha temporária expirou e deve ser redefinida por um administrador',

  ALERT_CODE_RESENT_SUCCESS: 'Código enviado com sucesso',
  ALERT_CODE_RESENT_FAIL: 'Erro ao enviar código',
  ALERT_CODE_SENT: 'Enviamos um código de verificação no seu e-mail.',
  ALERT_PASSWORD_UPDATED_SUCCESS: 'Nova senha cadastrada com sucesso',
  ALERT_USER_SUCCESSFULLY_VERIFIED: 'Usuário verificado com sucesso',

  ERROR_ACCESS_DENIED: 'Acesso negado',
  ERROR_INVALID_CREDENTIALS: 'Credenciais inválidas',
  ERROR_UNEXPECTED: 'Algo de errado aconteceu.',

  LABEL_ACTIONS: 'Ações',
  LABEL_CODE: 'Código',
  LABEL_CODE_PLACEHOLDER: 'Inserir código',
  LABEL_COMPANY_COLOR_BACKGROUND: 'Cor do fundo',
  LABEL_COMPANY_COLOR_FOREGROUND: 'Cor da fonte',
  LABEL_COMPANY_NAME: 'Nome da empresa',
  LABEL_COMPANY_NAME_PLACEHOLDER: 'Izap',
  LABEL_COMPANY_SLUG: 'URL da empresa',
  LABEL_COMPANY_SLUG_PLACEHOLDER: 'izap',
  LABEL_CONFIRM: 'Confirmar',
  LABEL_CREATING: 'Criando',
  LABEL_EMAIL: 'E-mail',
  LABEL_ENTER: 'Entrar',
  LABEL_ENTERING: 'Entrando',
  LABEL_FAMILY_NAME: 'Sobrenome',
  LABEL_GIVEN_NAME: 'Primeiro nome',
  LABEL_HI: 'Olá',
  LABEL_LOGO: 'Logotipo',
  LABEL_NEXT: 'Próximo',
  LABEL_NEW_PASSWORD: 'Nova Senha',
  LABEL_NEW_PASSWORD_PLACEHOLDER: 'Sua nova senha',
  LABEL_PASSWORD: 'Senha',
  LABEL_PASSWORD_PLACEHOLDER: 'Sua senha',
  LABEL_RECOVER: 'Enviar código',
  LABEL_RECOVERING: 'Enviando código',
  LABEL_REGISTERING: 'Registrando',
  LABEL_SAVE: 'Salvar',
  LABEL_SAVING: 'Salvando',
  LABEL_SEARCH_PLACEHOLDER: 'Pesquisar',
  LABEL_SEND: 'Enviar',
  LABEL_SENDING: 'Enviando',
  LABEL_START_NOW: 'Iniciar agora',

  LINK_LABEL_SIGN_UP: 'Ou Cadastre-se',
  LINK_LABEL_FORGOT_PASSWORD: 'Esqueceu a senha?',
  LINK_LABEL_SUPPORT: 'Suporte',
  LINK_LOGOUT: 'Sair',

  MENU_MY_TESTS: 'Meus testes',
  MENU_MY_CANDIDATES: 'Meus candidatos',

  TITLE_ADD_YOUR_COMPANY_S_VISUAL_FEATURES: 'Adicione as características visuais de sua empresa',
  TITLE_FORGOT_PASSWORD: 'Recupere sua senha',
  TITLE_PASSWORD_RECOVERY: 'Criar nova senha',
  TITLE_SIGN_IN: 'Para iniciar faça seu login',
  TITLE_SIGN_UP: 'Seja bem vindo (a), para iniciar seu cadastro preencha os dados',

  VALIDATE_INVALID_EMAIL: 'Email inválido',
  VALIDATE_INVALID_CPF: 'CPF Inválido',
  VALIDATE_MAX_PASSWORD: 'Máximo de 24 caracteres',
  VALIDATE_MIN_PASSWORD: 'Mínimo de 8 caracteres',
  VALIDATE_ONE_LOWER_CHAR: 'Pelo menos uma letra minúscula',
  VALIDATE_ONE_NUMBER: 'Pelo menos um número',
  VALIDATE_ONE_SPECIAL_CHAR: 'Pelo menos um caracter especial',
  VALIDATE_ONE_UPPER_CHAR: 'Pelo menos uma letra maiúscula',
  VALIDATE_ONLY_NUMBER: 'Somente números',
  VALIDATE_PASSWORD_NOT_EQUALS: 'As senhas não são iguais',
  VALIDATE_REQUIRED: 'Campo obrigatório',
  VALIDATE_REQUIRED_COMPANY_NAME: 'Necessário preencher o nome da empresa',

  //  PAGES
  //  MY_TESTS
  PAGE_MY_TESTS__DESCRIPTION: 'Agilize o processo seletivo de desenvolvedores',
  PAGE_MY_TESTS__TABLE_HEADER_VACANCY_NAME: 'Nome da vaga',
  PAGE_MY_TESTS__TABLE_HEADER_PROGRESS: 'Andamento',
  PAGE_MY_TESTS__TABLE_HEADER_AVERAGE_SCORE: 'Pontuação média',
  PAGE_MY_TESTS__WELCOME: 'Obrigado por se candidatar em nossa vaga e bem-vindo à nossa avaliação.',
  PAGE_MY_TESTS__CHANCE: 'Esse processo dará a chance de mostrar suas habilidades e se destacar!',
  PAGE_MY_TESTS__GOODLUCK: 'Boa Sorte!',
  PAGE_MY_TESTS__BEFORE_START: 'Antes de iniciar leia as instruções:',
  PAGE_MY_TESTS__INFO_1: 'A avaliação é cronometrada. O tempo é mostrado na parte superior da tela;',
  PAGE_MY_TESTS__INFO_3: 'Você pode usar a calculadora, caneta e papel.',
  PAGE_MY_TESTS__LABEL_DURATION: 'Tempo de duração:',
  PAGE_MY_TESTS__BUTTON_START: 'Iniciar agora',
  PAGE_MY_TESTS__BUTTON_NEXT: 'Próximo',
  PAGE_MY_TESTS__LABEL_PROGRESS: 'Progresso',
  PAGE_MY_TESTS__RELAX_TIME: 'Tire um tempo para relaxar',
  PAGE_MY_TESTS__CONTINUE_TEST: 'E contine o teste',
  PAGE_MY_TESTS__TEST_FINISH: 'Teste finalizado',
  PAGE_MY_TESTS__THANK_YOU: 'Obrigado(a) por Participar',
  PAGE_MY_TESTS__CONTACT: 'Em breve entraremos em contato',
  // MY_CANDIDATES
  PAGE_MY_CANDIDATES__TITLE: 'Todos candidatos',
  PAGE_MY_CANDIDATES__TABLE_HEADER_RANKING: 'Ranking',
  PAGE_MY_CANDIDATES__TABLE_HEADER_NAME: 'Nome',
  PAGE_MY_CANDIDATES__TABLE_HEADER_EMAIL: 'E-mail',
  PAGE_MY_CANDIDATES__TABLE_HEADER_POINTS: 'Pontuação',
  PAGE_MY_CANDIDATES__TABLE_HEADER_STATUS: 'Status',

  // CREATE_OPPORTUNITY
  PAGE_CREATE_OPPORTUNITY__TITLE: 'Qual seria a vaga?',
  PAGE_CREATE_OPPORTUNITY__FORM_DESCRIPTION_PLACEHOLDER: 'Digite aqui o nome para a vaga',

  // ADD_SKILLS_OPPORTUNITY
  PAGE_ADD_SKILLS_OPPORTUNITY__TITLE: 'Quais skills?',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_SKILL_TITLE: 'Skill',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_SKILL_PLACEHOLDER: 'Selecione uma skill',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_LEVEL_TITLE: 'Nível',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_LEVEL_PLACEHOLDER: 'Selecione um nível',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_CATEGORY_TITLE: 'Categoria',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_CATEGORY_PLACEHOLDER: 'Selecione uma Categoria',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_TIME_TITLE: 'Tempo',
  PAGE_ADD_SKILLS_OPPORTUNITY__FORM_TIME_PLACEHOLDER: 'De acordo com o nível',

  // SHARE_OPPORTUNITY
  PAGE_SHARE_OPPORTUNITY__TITLE: 'Tudo pronto, agora convide seus candidatos',
  PAGE_SHARE_OPPORTUNITY__SHARE_LINK_ON_MAIL: 'Compartilhar link através do e-mail',
  PAGE_SHARE_OPPORTUNITY__COPY_LINK: 'Copiar link',
  PAGE_SHARE_OPPORTUNITY__COPY_CUSTOMIZE_TEMPLATE_EMAIL_TITLE: 'Se preferir personalize seu e-mail para enviar',
  PAGE_SHARE_OPPORTUNITY__FORM_CANDIDATES_NAME_PLACEHOLDER: 'Nome do candidato',
  PAGE_SHARE_OPPORTUNITY__FORM_CANDIDATES_EMAIL_PLACEHOLDER: 'Adicione o e-mail do candidato',
  PAGE_SHARE_OPPORTUNITY__FORM_CANDIDATES_BUTTON_COPY_LINK: 'Copiar link',

  // Commons
  FORM_SELECT_OPPORTUNITIES_TYPE_PLACEHOLDER: 'Selecione o tipo de vaga',
  UNAVAILABLE: 'Indisponível'
}
