import React from 'react'
import { useAlertBox } from '../../../contexts'
import * as AlertBoxStyled from './alert-box-styled'
import { Button } from '../../ui'

const AlertBox = () => {
  const { content, open, closeAlertBox } = useAlertBox()

  const handleClose = () => {
    closeAlertBox()
  }

  if (!open) return null

  return (
    <>
      <AlertBoxStyled.AlertDialogOverlay onClick={handleClose} />
      <AlertBoxStyled.AlertDialogContent>
        <AlertBoxStyled.AlertDialogHeader />
        <AlertBoxStyled.AlertDialogBody>
          {typeof content === 'object'
            ? content
            : <div dangerouslySetInnerHTML={{ __html: content }} />}
        </AlertBoxStyled.AlertDialogBody>
        {typeof content !== 'object' && (
          <AlertBoxStyled.AlertDialogFooter>
            <Button color="blue" onClick={handleClose}>Ok</Button>
          </AlertBoxStyled.AlertDialogFooter>
        )}
      </AlertBoxStyled.AlertDialogContent>
    </>
  )
}

export default AlertBox
