import styled from 'styled-components'
import { BoxProps } from '..'
import { BoxHelper } from '../box/utils/box-helper'

export type FlexProps = BoxProps & {
  flexWrap?: 'wrap'|'wrap-reverse'|'nowrap'
  flexDir?: 'column'|'column-reverse'|'row'|'row-reverse'
  alignItems?: 'baseline'|'center'|'flex-end'|'flex-start'|'normal'|'stretch'
  alignContent?: 'stretch'|'center'|'flex-end'|'flex-start'|'space-between'|'space-around'
  justifyItems?: 'baseline'|'center'|'end'|'flex-end'|'flex-start'|'left'|'legacy'|'normal'|'right'|'revert'|'self-end'|'self-start'|'start'|'stretch'
  justifyContent?: 'flex-start'|'flex-end'|'center'|'space-between'|'space-around'|'space-evenly'
  alignSelf?: 'baseline'|'center'|'flex-start'|'flex-end'|'self-start'|'self-end'|'normal'|'stretch'
  justifySelf?: 'baseline'|'center'|'end'|'flex-end'|'flex-start'|'left'|'normal'|'right'|'revert'|'self-end'|'self-start'|'start'|'stretch'
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${(props) => BoxHelper.apply(props)}
`
