import React, { useState } from 'react'

type ModalContextProps = {
  open: boolean
  addOpen: (value: boolean) => void
  content: React.ReactElement|null
  addContent: (value: any) => void
  title: string|null
  addTitle: (value: string|null) => void
  customClose: any
  addCustomClose: (value: any) => void
  modal: (data: any, title?: string) => void
  modalClose: () => void
}

const INITIAL_VALUES: ModalContextProps = {
  open: false,
  addOpen: () => {},
  content: null,
  addContent: () => {},
  title: null,
  addTitle: () => {},
  customClose: null,
  addCustomClose: () => {},
  modal: () => {},
  modalClose: () => {}
}

const ModalContext = React.createContext<ModalContextProps>(INITIAL_VALUES)

export const ModalProvider: React.FC = ({ children }: any) => {
  const [open, addOpen] = useState(INITIAL_VALUES.open)
  const [content, addContent] = useState(INITIAL_VALUES.content)
  const [title, addTitle] = useState(INITIAL_VALUES.title)
  const [customClose, addCustomClose] = useState(INITIAL_VALUES.customClose)

  const modal = (data: any, title?: string) => {
    addOpen(true)
    addContent(data)
    addTitle(title || null)
  }

  const modalClose = () => {
    addOpen(false)
    addContent(null)
    addTitle(null)
  }

  return (
    <ModalContext.Provider value={{
      open,
      addOpen,
      content,
      addContent,
      title,
      addTitle,
      customClose,
      addCustomClose,
      modal,
      modalClose
    }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = React.useContext(ModalContext)

  if (!context) throw new Error('useModal must be used within a provider ModalProvider')

  return { ...context }
}
