import React from 'react'
import { Box, BoxProps } from '..'

type ColNumber = 1|2|3|4|5|6|7|8|9|10|11|12

type ResponsiveProps = 'xs'|'sm'|'md'|'lg'|'xl'|'xxl'

type ColProps = BoxProps & {
  xs?: ColNumber
  sm?: ColNumber
  md?: ColNumber
  lg?: ColNumber
  xl?: ColNumber
  xxl?: ColNumber
  children: string|React.ReactElement|React.ReactElement[]
}

const Col: React.FC<ColProps> = ({ children, ...props }: ColProps) => {
  const positions = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as ResponsiveProps[]
  const numColumns = 12
  const colValue = 100 / numColumns
  const size = new Array(positions.length).fill('100%')

  positions.forEach((value, index) => {
    if (props[value]) {
      size.fill(`${parseFloat((props[value]! * colValue).toFixed(5))}%`, index)
      Reflect.deleteProperty(props, value)
    }
  })

  return (
    <Box w={size} px={10} {...props}>
      {children}
    </Box>
  )
}

export default Col
