import styled from 'styled-components'

export const ModalDialogOverlay = styled.div`
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 60;
`

export const ModalDialogContent = styled.div`
  position: fixed;
  width: 100%;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 61;
  max-width: 90%;
  border-radius: 5px;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, .08);
  padding: 30px;

  @media (min-width: 576px) {
    max-width: 480px;
  }
  @media (min-width: 768px) {
    max-width: 700px;
  }
  @media (min-width: 992px) {
    max-width: 870px;
  }
  @media (min-width: 1200px) {
    max-width: 870px;
  }
`

export const ModalDialogHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

export const ModalDialogHeaderTitle = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
`

export const ModalDialogClose = styled.div`
  cursor: pointer;
  margin-left: auto;
  font-size: 25px;
  width: 30px;
  text-align: center;
  background: none;
  color: #009BDF;
`

export const ModalDialogBody = styled.div`
  padding: 0;
`

export const ModalDialogFooter = styled.div``
