import styled from 'styled-components'

type SizesProps = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

type ContainerProps = {
  size?: SizesProps
  fluid?: boolean
}

const sizes: SizesProps[] = ['sm', 'md', 'lg', 'xl', 'xxl']

const defaultSize = 'xl'

const getPositionSize = (size: SizesProps = defaultSize): number => {
  return sizes.indexOf(size) >= 0 ? sizes.indexOf(size) : sizes.indexOf(defaultSize)
}

const mediaContainer = (value: SizesProps): string => {
  switch (value) {
    case 'sm': return '576px'
    case 'md': return '768px'
    case 'lg': return '992px'
    case 'xl': return '1200px'
    case 'xxl': return '1400px'
    default: return '0'
  }
}

const maxWidthContainer = (value: SizesProps): string => {
  switch (value) {
    case 'sm': return '540px'
    case 'md': return '720px'
    case 'lg': return '960px'
    case 'xl': return '1140px'
    case 'xxl': return '1320px'
    default: return '0'
  }
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;

  ${(props: ContainerProps) => {
    if (!props.fluid) {
      let css = ''
      for (let i = 0; i <= getPositionSize(props.size); i++) {
        css += `
          @media (min-width: ${mediaContainer(sizes[i])}) {
            max-width: ${maxWidthContainer(sizes[i])};          
          }
        `
      }
      return css
    }
  }}
`
