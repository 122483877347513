import styled from 'styled-components'

const Style = styled.div`
  .table {
    border-radius: 9px;
    border: 1px solid #c4c4c4;

    .table-body {
      max-height: 390px;
      overflow: auto;
      
      ::-webkit-scrollbar {
        width: 10px;
      }
      
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      ::-webkit-scrollbar-thumb {
        background: #212366;
        border-radius: 5px;
      }
    }

    .table-row {
      border-bottom: 1px solid #c4c4c4;
      padding: 10px;
      &:last-of-type {
        border-bottom: 0;
      }
        
      .table-col {
        padding: 10px;
      }
    }

    .table-header {
      .table-header-col{
        

        .table-label{
          font-weight: bold;
          font-size: 16px;
          line-height: 36px;
        }
      }
    }
  }
  
  .btn {
    border: 0;
    display: inline-block;
    padding: 7.5px 10px;
    font-weight: bold;
    font-size: 16px;
    border-radius: 9px;
    background: #f4f4f4;
    color: #232323;

    &.btn-success {
      background-color: #219653;
      color: #ffffff;
    }

    &.btn-warning {
      background-color: #F2994A;
      color: #ffffff;
    }

    &.btn-danger {
      background-color: #EB5757;
      color: #ffffff;
    }
  }
  
  
`

export default Style
