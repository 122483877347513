import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { GlobalStyle } from './presentation/assets/styled-components/global'
import { MakeRouteFactory as Route } from './main/factory/routes/route-factory'
import { AlertBoxProvider, ModalProvider } from './presentation/contexts'
import { AlertBox, Modal } from './presentation/components/ui'

ReactDOM.render(
  <React.StrictMode>
    <ModalProvider>
      <AlertBoxProvider>
        <GlobalStyle />
        <Route />
        <Modal />
        <AlertBox />
      </AlertBoxProvider>
    </ModalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
