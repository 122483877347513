import React from 'react'
import { guardsRoutes } from '../usecases/routes/guards-routes'
import { Routes } from '../../../presentation/components/router'
import { addLanguage } from '../../../presentation/adapters/translate/translate'

addLanguage('pt_BR')

export const MakeRouteFactory = () => {
  return (
    <Routes guardsRoutes={guardsRoutes} />
  )
}
