import React from 'react'
import { LayoutBase } from '../../../presentation/components/layouts'

type MakeBaseProps = {
  children?: React.ReactNode
}
const MakeBase: React.FC<MakeBaseProps> = ({ children }: MakeBaseProps) => {
  return (
    <LayoutBase>
      {children}
    </LayoutBase>
  )
}

export default MakeBase
