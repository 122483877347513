import styled from 'styled-components'
import checkSvg from '../../assets/svg/check.svg'

export const PageStyled = styled.div`
  min-height:calc(100vh - 90px);
  display: flex;
  align-items: center;
  color:#232323;
  padding-top: 200px;
`

export const CardStyled = styled.div`
  background-color:#fff;
  border-radius: 16px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  padding:30px 50px;
  .auxiliar-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    h2, h1{
      color:#212366;
      margin-bottom: 15px;
    }
    img{
      max-width: 237px;
    }
    p{
      margin-bottom: 50px;
    }
  }
`

export const OptionStyled = styled.label`
  width:100%;
  display:flex;
  align-items: center;
  color:#232323;
  margin-bottom: 15px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  input[type=radio]:checked + .check-box-custom{
    background-color: #009bdf;
    /* background-image: url(${checkSvg}); */
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    i{
      display: block;
    }
  }
  .check-box-custom {
    min-width: 48px;
    height: 42px;
    background-color: #D9D9D9;
    display: block;
    margin-right: 10px;
    border-radius: 8px;
     i{
      display: none;
      color: #fff;
      font-size: 2em;
    }
}
`
export const QuestionStyled = styled.p`
  margin-top: 30px;
  img{
    max-width: 100%;
  }
`
export const ProgressText = styled.h4`
  color:#212366;
  font-weight: bold;
`
export const NumberStyled = styled.h2`
  font-size:2.3em;
  color:#212366;
`
