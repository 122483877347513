import React from 'react'
import { Flex, FlexProps } from '..'

type RowProps = FlexProps & {
  children: React.ReactElement|React.ReactElement[]
}

const Row: React.FC<RowProps> = ({ children, ...props }: RowProps) => {
  return (
    <Flex flexWrap="wrap" mx="-10px" {...props}>
      {children}
    </Flex>
  )
}

export default Row
