export class BoxHelper {
  private static readonly breakpoints = ['576px', '768px', '992px', '1200px', '1400px']

  private static readonly allowedNumbers = ['z-index', 'opacity']

  private static readonly alloweds = [
    'd',
    'w', 'h', 'maxW', 'minW', 'maxH', 'minH',
    'px', 'py', 'pl', 'pr', 'pt', 'pb', 'p',
    'mx', 'my', 'ml', 'mr', 'mt', 'mb', 'm',
    'display', 'flexWrap', 'flexDir', 'flexBasics', 'flexGrow',
    'alignItems', 'alignContent',
    'alignSelf', 'justifySelf',
    'justifyItems', 'justifyContent',
    'textAlign', 'pos',
    'bg', 'bgc',
    'opacity', 'zIndex',
    'fw'
  ]

  private static removeAbbreviationAttribute (prop: string): string | string[] {
    switch (prop) {
      case 'd': return 'display'
      case 'w': return 'width'
      case 'h': return 'height'
      case 'px': return ['padding-left', 'padding-right']
      case 'py': return ['padding-top', 'padding-bottom']
      case 'pl': return 'padding-left'
      case 'pr': return 'padding-right'
      case 'pt': return 'padding-top'
      case 'pb': return 'padding-bottom'
      case 'p': return 'padding'
      case 'mx': return ['margin-left', 'margin-right']
      case 'my': return ['margin-top', 'margin-bottom']
      case 'ml': return 'margin-left'
      case 'mr': return 'margin-right'
      case 'mt': return 'margin-top'
      case 'mb': return 'margin-bottom'
      case 'm': return 'margin'
      case 'pos': return 'position'
      case 'bg': return 'background'
      case 'bgc': return 'background-color'
      case 'fw': return 'font-weight'
      case 'flexDir': return 'flex-direction'
      case 'maxW': return 'max-width'
      case 'minW': return 'min-width'
      case 'maxH': return 'max-height'
      case 'minH': return 'min-height'
      default: return prop
    }
  }

  private static sanitize (str: string) {
    const noCamel = str.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const newStr = noCamel.replace(/\s|_/g, '-')
    return newStr.toLowerCase()
  }

  private static renderResponsive (index: number, prop: string, value: string | number) {
    if (index) {
      return `
        @media (min-width: ${this.breakpoints[index + 1]}) {
          ${prop}: ${this.renderValue(value, prop)};
        }
      `
    }

    return `${prop}: ${this.renderValue(value, prop)};`
  }

  private static renderValue (value: string|number, prop: string) {
    return typeof value === 'number' && !this.allowedNumbers.includes(prop) ? `${value}px` : value
  }

  private static loadProps (key: string, value: number|number[]|string|string[]): any {
    if (Array.isArray(value)) {
      return value.map((val, index) => this.renderResponsive(index, key, val)).join('')
    }

    return this.renderResponsive(0, key, value)
  }

  static apply (props: any) {
    return Object.keys(props)
      .filter(key => this.alloweds.includes(key))
      .map(key => {
        const attribute = this.removeAbbreviationAttribute(key)

        if (Array.isArray(attribute)) {
          return attribute
            .map(attr => this.loadProps(
              this.sanitize(attr),
              props[key]
            )).join('\n')
        }

        const prop = this.loadProps(this.sanitize(attribute), props[key])
        return Array.isArray(prop) ? prop.join('\n') : prop
      })
  }
}
